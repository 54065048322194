import React, { useState, useEffect, useMemo } from 'react';
import {
  IonContent,
  IonPage,
  IonSearchbar,
  IonButton,
  IonModal,
  IonToolbar,
  IonHeader,
  IonTitle,
  IonButtons,
  IonLoading,
  IonToast,
} from '@ionic/react';
import SearchResults from './SearchResults';
import SearchFilters from './SearchFilters';
import SearchNoResults from './SearchNoResults';
import { name } from 'country-emoji';
import { getIndustryName } from '../../utils/industries';
import { useTranslation } from 'react-i18next';
import algoliasearch from 'algoliasearch/lite';
import './Search.css';

const client = algoliasearch('SP4J31TN4G', '9d5cf5833412eba57f0a6b324e98e474');
const index = client.initIndex('prod_WALINK');

type AlgoliaHits = {
  hits: AlgoliaHit[];
  nbPages: number;
  page: number;
};

export type AlgoliaHit = {
  objectID: string;
  title: string;
  link: string;
  image: string;
  description: string;
  country: string;
  cityId: string;
  cityName: string;
  cityFormatted: string;
  categories: string[];
};

export type City = {
  formatted_address: string;
  name: string;
  place_id: string;
};

interface SearchProps {
  setCountryCode: (c: string) => void;
  countryCode: string;
  saveToHistory: (to: string, date: number) => void;
}

const Search: React.FC<SearchProps> = ({
  countryCode,
  setCountryCode,
  saveToHistory,
}) => {
  const [searchText, setSearchText] = useState('');
  const [mode, setMode] = useState<'filters' | 'results' | 'no-results' | ''>(
    ''
  );
  const [category, setCategory] = useState('');
  const [city, setCity] = useState<City | false>(false);
  const [allCities, setAllCities] = useState(true);
  const [loading, setLoading] = useState(false);
  const [hits, setHits] = useState<AlgoliaHit[]>([]);
  const [nextPage, setNextPage] = useState(0);
  const [toast, setToast] = useState({
    isOpen: false,
    message: '',
    type: 'primary',
  });
  const { t } = useTranslation('outside');

  const categoryName = useMemo(() => {
    return getIndustryName(category);
  }, [category]);

  const countryName = useMemo(() => {
    const newCountryName = name(countryCode);
    if (newCountryName) return newCountryName;
    return countryCode;
  }, [countryCode]);

  useEffect(() => {
    const currentCategory = localStorage.getItem('category');
    if (currentCategory) {
      setCategory(currentCategory);
    }
  }, []);

  const changeCategory = (cat: string) => {
    setCategory(cat);
    localStorage.setItem('category', cat);
  };

  useEffect(() => {
    const oneCity = localStorage.getItem('oneCity');
    if (oneCity === 'yes') {
      setAllCities(false);
    } else {
      setAllCities(true);
    }
  }, []);

  useEffect(() => {
    const currentCity = localStorage.getItem(
      `${countryCode.toLowerCase()}-city`
    );
    if (currentCity) {
      setCity(JSON.parse(currentCity));
    } else {
      setCity(false);
    }
  }, [countryCode]);

  const changeCity = (c: City) => {
    const cityString = JSON.stringify(c);
    localStorage.setItem(`${countryCode.toLowerCase()}-city`, cityString);
    localStorage.setItem('oneCity', 'yes');
    setCity(c);
  };

  const changeAllCities = (all: boolean) => {
    if (all) {
      setAllCities(true);
      localStorage.setItem('oneCity', 'no');
    } else {
      setAllCities(false);
      localStorage.setItem('oneCity', 'yes');
    }
  };

  const handleSearch = async (page: number) => {
    const search = searchText.trim();
    if (!search || search.length > 300) {
      setToast({
        isOpen: true,
        type: 'danger',
        message: t('errors.typeSomething'),
      });
      return;
    }
    setLoading(true);
    const filterCategory = category ? ` AND categories:${category}` : '';
    const filterCity = city && !allCities ? ` AND cityId:${city.place_id}` : '';
    try {
      const response: AlgoliaHits = await index.search(search, {
        filters: `country:${countryCode}` + filterCategory + filterCity,
        page,
        hitsPerPage: 20,
      });
      if (response.hits && response.hits.length > 0) {
        setHits([...hits, ...response.hits]);
        setMode('results');
        if (response.nbPages > 1 && response.page + 1 < response.nbPages) {
          setNextPage(response.page + 1);
        } else {
          setNextPage(0);
        }
      } else {
        setMode('no-results');
        clearResults();
      }
      setLoading(false);
    } catch (e) {
      console.log(e);
      setToast({
        isOpen: true,
        type: 'danger',
        message: t('errors.default'),
      });
      setLoading(false);
    }
    //@ts-ignore
    window.gtag('event', 'search');
  };

  const clearResults = () => {
    setHits([]);
    setNextPage(0);
  };

  return (
    <IonPage>
      <IonContent fullscreen>
        <div className='container'>
          <div className='search-content'>
            <div style={{ margin: '1rem', textAlign: 'center' }}>
              <h1>
                <strong>{t('search.title')}</strong>
              </h1>
              <p>{t('search.description')}</p>
            </div>
            <div>
              <IonSearchbar
                value={searchText}
                onIonChange={(e) => setSearchText(e.detail.value!)}
                onIonCancel={clearResults}
                onIonClear={clearResults}
                placeholder={t('search.barPlaceholder')}
              />
            </div>
            <div style={{ marginTop: '1rem' }}>
              <IonButton
                expand='block'
                color='success'
                onClick={() => handleSearch(0)}
              >
                {t('search.search')}
              </IonButton>
            </div>
            <div style={{ marginTop: '1rem', textAlign: 'center' }}>
              <small>
                {t('search.showingResults')} {countryName} -{' '}
                {city && !allCities
                  ? `${city.name}`
                  : `${t('search.allCities')}`}{' '}
                -{' '}
                {category
                  ? `${t('search.in')} ${categoryName}`
                  : `${t('search.allCategories')}`}
              </small>
              <div>
                <IonButton
                  fill='clear'
                  size='small'
                  onClick={() => setMode('filters')}
                >
                  {t('search.filter')}
                </IonButton>
              </div>
            </div>
          </div>
        </div>
        <IonModal isOpen={mode ? true : false} onDidDismiss={clearResults}>
          <IonHeader translucent>
            <IonToolbar>
              <IonTitle>{t('search.' + mode)}</IonTitle>
              <IonButtons slot='end'>
                <IonButton onClick={() => setMode('')}>
                  {t('search.close')}
                </IonButton>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonContent fullscreen={true}>
            {mode === 'results' && (
              <SearchResults
                results={hits}
                query={searchText}
                category={category}
                countryName={countryName}
                city={city}
                allCities={allCities}
                searchNext={handleSearch}
                nextPage={nextPage}
                saveToHistory={saveToHistory}
              />
            )}
            {mode === 'filters' && (
              <SearchFilters
                countryCode={countryCode}
                setCountryCode={setCountryCode}
                setCategory={changeCategory}
                category={category}
                countryName={countryName}
                city={city}
                changeCity={changeCity}
                allCities={allCities}
                changeAllCities={changeAllCities}
              />
            )}
            {mode === 'no-results' && (
              <SearchNoResults
                query={searchText}
                category={categoryName}
                countryName={countryName}
                city={city}
                allCities={allCities}
              />
            )}
          </IonContent>
        </IonModal>
        <IonLoading isOpen={loading} message={'Please wait...'} />
      </IonContent>
      <IonToast
        isOpen={toast.isOpen}
        position='top'
        message={toast.message}
        color={toast.type}
        duration={2000}
        onDidDismiss={() =>
          setToast({
            isOpen: false,
            message: '',
            type: 'primary',
          })
        }
      />
    </IonPage>
  );
};

export default Search;
