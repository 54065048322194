import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import LanguageDetector from 'i18next-browser-languagedetector'
import outside_es from './translations/es/outside_es.json'
import outside_en from './translations/en/outside_en.json'

const options = {
  // order and from where user language should be detected
  order: ['localStorage', 'navigator'],

  // keys or params to lookup language from
  lookupLocalStorage: 'i18nextLng',
}
i18n
  .use(initReactI18next)
  .use(LanguageDetector)
  .init({
    detection: options,
    interpolation: { escapeValue: false }, // React already does escaping
    load: 'languageOnly',
    // lng: 'en',
    fallbackLng: 'en',
    resources: {
      en: {
        outside: outside_en,
      },
      es: {
        outside: outside_es,
      },
    },
  })

export default i18n
