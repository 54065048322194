export const industryOptionsES = [
  { id: 'advertising_marketing', label: 'Marketing y publicidad' },
  { id: 'agriculture', label: 'Agricultura' },
  { id: 'arts_entertainment', label: 'Arte y Entretenimiento' },
  { id: 'automotive_aircraft_boat', label: 'Automotriz y Aeronáutico' },
  {
    id: 'beauty_cosmetic_personal_care',
    label: 'Belleza, Cosméticos y Cuidado personal',
  },
  { id: 'commercial_industrial', label: 'Industria y Comercio' },
  { id: 'education', label: 'Educación' },
  { id: 'finance', label: 'Finanzas' },
  { id: 'food_beverage', label: 'Alimentos y Bebidas' },
  { id: 'hotel_tourism', label: 'Hotelería y Turismo' },
  { id: 'legal', label: 'Legal' },
  { id: 'services_consulting', label: 'Servicios y Consultoría' },
  { id: 'media_news', label: 'Medios de comunicación' },
  { id: 'medical_health', label: 'Medicina y Salud' },
  { id: 'ngo', label: 'ONG' },
  { id: 'nonprofit', label: 'Fundaciones / Organización sin ánimo de lucro' },
  { id: 'public_government', label: 'Gobierno y Sector Público' },
  { id: 'realestate', label: 'Bienes raíces' },
  { id: 'technology', label: 'Tecnología' },
  { id: 'shopping_retail', label: 'Ventas al por menor' },
  { id: 'sports_gym', label: 'Deportes / GYM' },
  { id: 'software_internet', label: 'Software e Internet' },
  { id: 'travel_transportation', label: 'Viajes y Transporte' },
  { id: 'public_figure', label: 'Figura pública' },
  { id: 'other', label: 'Otro' },
];

export const industryOptionsEN = [
  { id: 'advertising_marketing', label: 'Advertising/Marketing' },
  { id: 'agriculture', label: 'Agriculture & Farming' },
  { id: 'arts_entertainment', label: 'Arts & Entertainment' },
  { id: 'automotive_aircraft_boat', label: 'Automotive, Aircraft & Boat' },
  {
    id: 'beauty_cosmetic_personal_care',
    label: 'Beauty, Cosmetic & Personal Care',
  },
  { id: 'commercial_industrial', label: 'Commercial & Industrial' },
  { id: 'education', label: 'Education' },
  { id: 'finance', label: 'Finance' },
  { id: 'food_beverage', label: 'Food & Beverage' },
  { id: 'hotel_tourism', label: 'Hotel & Tourism' },
  { id: 'legal', label: 'Legal' },
  { id: 'services_consulting', label: 'Services & Consulting' },
  { id: 'media_news', label: 'Media/News Company' },
  { id: 'medical_health', label: 'Medical & Health' },
  { id: 'ngo', label: 'Non-Governmental Organization (NGO)' },
  { id: 'nonprofit', label: 'Nonprofit Organization' },
  { id: 'public_government', label: 'Public & Government Service' },
  { id: 'realestate', label: 'Real Estate' },
  { id: 'technology', label: 'Technology' },
  { id: 'shopping_retail', label: 'Shopping & Retail' },
  { id: 'sports_gym', label: 'Sports & Gym' },
  { id: 'software_internet', label: 'Software & Internet' },
  { id: 'travel_transportation', label: 'Travel & Transportation' },
  { id: 'public_figure', label: 'Public Figure' },
  { id: 'other', label: 'Other' },
];

export function getIndustries(lang?: string) {
  if (lang && lang.startsWith('es')) {
    return industryOptionsES;
  } else {
    return industryOptionsEN;
  }
}

export function getIndustriesById(ids: string[], lang?: string) {
  if (!ids) return [];
  if (lang && lang.startsWith('es')) {
    const categoriesES = industryOptionsES.filter(
      (c) => ids.indexOf(c.id) !== -1
    );
    return categoriesES;
  } else {
    const categoriesEN = industryOptionsEN.filter(
      (c) => ids.indexOf(c.id) !== -1
    );
    return categoriesEN;
  }
}

export function getIndustriesLabels(ids: string[], lang?: string) {
  if (!ids) return [];
  if (lang && lang.startsWith('es')) {
    const categoriesES = industryOptionsES
      .filter((c) => ids.indexOf(c.id) !== -1)
      .map((c) => c.label);
    return categoriesES;
  } else {
    const categoriesEN = industryOptionsEN
      .filter((c) => ids.indexOf(c.id) !== -1)
      .map((c) => c.label);
    return categoriesEN;
  }
}

export function getIndustryName(id: string, lang?: string) {
  if (!id) return '';
  if (lang && lang.startsWith('es')) {
    const category = industryOptionsES.filter((c) => c.id === id);
    if (category.length > 0) {
      return category[0].label;
    } else {
      return id;
    }
  } else {
    const category = industryOptionsEN.filter((c) => c.id === id);
    if (category.length > 0) {
      return category[0].label;
    } else {
      return id;
    }
  }
}

type ObjWithFields = { [key: string]: string; }

export function getIndusriesObject(lang?: string) {
  let obj: ObjWithFields = {};
  if (lang && lang.startsWith('es')) {
    for (let industry of industryOptionsES) {
      obj[industry.id] = industry.label;
    }
  } else {
    for (let industry of industryOptionsEN) {
      obj[industry.id] = industry.label;
    }
  }
  return obj;
}
