import React from 'react';
import {
  IonContent,
  IonPage,
  IonList,
  IonItem,
  IonLabel,
  IonIcon,
  IonButton,
  IonHeader,
  IonToolbar,
} from '@ionic/react';
import { closeOutline, chatbubbleEllipsesOutline } from 'ionicons/icons';
import { formatTimestamp } from '../../utils/formatTimestamp';
import { HistoryList } from '../../App';
import { useTranslation } from 'react-i18next';
import './History.css';

interface HistoryProps {
  history: HistoryList;
  setHistory: (l: HistoryList) => void;
}

const History: React.FC<HistoryProps> = ({ history, setHistory }) => {
  const { t } = useTranslation('outside');
  const removeItem = (index: number) => {
    if (history && history !== 'false') {
      const newHistory = history
        .slice(0, index)
        .concat(history.slice(index + 1, history.length));
      if (newHistory.length > 0) {
        localStorage.setItem('history', JSON.stringify(newHistory));
        setHistory(newHistory);
      } else {
        setHistory(undefined);
        localStorage.removeItem('history');
      }
    }
  };

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar mode='ios'></IonToolbar>
      </IonHeader>
      <IonContent fullscreen>
        {history === 'false' ? (
          <div className='container'>
            <div className='open-content'>
              <p>{t('history.disabled')}</p>
            </div>
          </div>
        ) : (
          <>
            {history ? (
              <IonList>
                {history.map((l, i) => (
                  <HistoryItem
                    key={l.date}
                    to={l.to}
                    date={l.date}
                    removeItem={removeItem}
                    index={i}
                  />
                ))}
              </IonList>
            ) : (
              <div className='container'>
                <div className='open-content'>
                  <p>{t('history.noHistory')}</p>
                </div>
              </div>
            )}
          </>
        )}
      </IonContent>
    </IonPage>
  );
};

interface ItemProps {
  to: string;
  date: number;
  removeItem: (index: number) => void;
  index: number;
}

const HistoryItem: React.FC<ItemProps> = ({ to, date, removeItem, index }) => {
  const goTo = (goto: string) => {
    let link = document.createElement('a');
    if (goto.startsWith('wa.link/')) {
      link.href = 'https://' + to;
    } else {
      link.href = `whatsapp://send?phone=${to}`;
    }
    link.click();
  };

  return (
    <IonItem>
      <IonLabel>
        <h2>{to}</h2>
        <p>{formatTimestamp(date)}</p>
      </IonLabel>
      <IonButton fill='clear' onClick={() => goTo(to)}>
        <IonIcon slot='icon-only' icon={chatbubbleEllipsesOutline} />
      </IonButton>
      <IonButton fill='clear' onClick={() => removeItem(index)}>
        <IonIcon slot='icon-only' icon={closeOutline} />
      </IonButton>
    </IonItem>
  );
};

export default History;
