import React, { useEffect, useState } from 'react';
import {
  IonList,
  IonItem,
  IonHeader,
  IonToolbar,
  IonTitle,
  IonIcon,
  IonButton,
  IonButtons,
  IonContent,
  IonModal,
  IonLabel,
  IonToggle,
  IonToast,
  IonSelect,
  IonSelectOption,
} from '@ionic/react';
import {
  bookmarkOutline,
  languageOutline,
  moon,
  shareSocialOutline,
} from 'ionicons/icons';
import { useTranslation } from 'react-i18next';

interface ModalProps {
  isOpen: boolean;
  isDark: boolean;
  saveChats: boolean;
  toggleDarkMode: (check: boolean) => void;
  toggleSaveChats: (check: boolean) => void;
  closeModal: () => void;
}

const SettingsModal: React.FC<ModalProps> = ({
  isOpen,
  closeModal,
  isDark,
  saveChats,
  toggleDarkMode,
  toggleSaveChats,
}) => {
  const [lang, setLang] = useState('en');
  const [toast, setToast] = useState({
    isOpen: false,
    message: '',
    type: 'primary',
  });
  const { t, i18n } = useTranslation('outside');

  const shareApp = () => {
    let newNavigator: any = window.navigator;
    if (newNavigator && newNavigator.share) {
      newNavigator
        .share({
          title: 'Open Walink',
          text: 'Open WhatsApp chats fast and easy without saving contacts',
          url: 'https://open.wa.link',
        })
        .then(() => {
          setToast({
            isOpen: true,
            message: 'Thanks for sharing!❤️',
            type: 'success',
          });
        })
        .catch((error) => {
          setToast({
            isOpen: true,
            message: 'Could not share on your device',
            type: 'danger',
          });
        });
    }
  };

  useEffect(() => {
    if (i18n.languages[0] === 'es') {
      setLang('es');
    } else {
      setLang('en');
    }
  }, [i18n.languages]);

  return (
    <>
      <IonModal isOpen={isOpen}>
        <IonHeader translucent>
          <IonToolbar>
            <IonTitle>{t('settings.title')}</IonTitle>
            <IonButtons slot='end'>
              <IonButton onClick={() => closeModal()}>
                {t('settings.close')}
              </IonButton>
            </IonButtons>
          </IonToolbar>
        </IonHeader>
        <IonContent fullscreen={true}>
          <IonList>
            <IonItem lines='none'>
              <IonIcon slot='start' icon={moon} />
              <IonLabel>{t('settings.darkMode')}</IonLabel>
              <IonToggle
                slot='end'
                name='darkMode'
                checked={isDark}
                onIonChange={(e) => toggleDarkMode(e.detail.checked)}
              />
            </IonItem>
            <IonItem lines='none'>
              <IonIcon slot='start' icon={bookmarkOutline} />
              <IonLabel>{t('settings.saveHistory')}</IonLabel>
              <IonToggle
                slot='end'
                name='save'
                checked={saveChats}
                onIonChange={(e) => toggleSaveChats(e.detail.checked)}
              />
            </IonItem>
            <IonItem lines='none'>
              <IonIcon slot='start' icon={languageOutline} />
              <IonLabel>{t('settings.language')}:</IonLabel>
              <IonSelect
                value={lang}
                onIonChange={(e) => {
                  setLang(e.detail.value);
                  i18n.changeLanguage(e.detail.value);
                }}
                cancelText={t('settings.cancel')}
              >
                <IonSelectOption value='en'>English</IonSelectOption>
                <IonSelectOption value='es'>Español</IonSelectOption>
              </IonSelect>
            </IonItem>
            <IonItem button onClick={shareApp} lines='none' detail={true}>
              <IonIcon slot='start' icon={shareSocialOutline} />
              <IonLabel>{t('settings.share')}</IonLabel>
            </IonItem>
          </IonList>
          <div className='footer'>
            <strong>
              <p>
                Made with{' '}
                <span role='img' aria-label='green heart'>
                  💚
                </span>{' '}
                by Walink Inc. {new Date().getFullYear()}
              </p>
            </strong>
            <small>{t('settings.footer')}</small>
          </div>
        </IonContent>
      </IonModal>

      <IonToast
        isOpen={toast.isOpen}
        position='top'
        message={toast.message}
        color={toast.type}
        duration={2000}
        onDidDismiss={() =>
          setToast({
            isOpen: false,
            message: '',
            type: 'primary',
          })
        }
      />
    </>
  );
};
export default SettingsModal;
