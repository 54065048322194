import React, { useState } from 'react';
import {
  IonButton,
  IonToast,
  IonPopover,
  IonList,
  IonItem,
  IonListHeader,
  getPlatforms,
} from '@ionic/react';
import { useTranslation } from 'react-i18next';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/bootstrap.css';

interface OpenProps {
  save: (to: string, date: number) => void;
  setCountryCode: (c: string) => void;
  countryCode: string;
}

const OpenPhone: React.FC<OpenProps> = ({
  save,
  setCountryCode,
  countryCode,
}) => {
  const [phone, setPhone] = useState<string>();
  const [popoverState, setShowPopover] = useState(false);
  const [error, setError] = useState<string>('');
  const { t } = useTranslation('outside');

  const handlePhoneChange: (
    phone: string,
    country: { name: string; countryCode: string }
  ) => void = (p, c) => {
    if (c.countryCode !== countryCode) {
      setCountryCode(c.countryCode);
    }
    if (error) {
      setError('');
    }
    setPhone(p);
  };

  const handleClick = () => {
    const currentPlatform = getPlatforms();
    if (currentPlatform.includes('desktop')) {
      setShowPopover(true);
      console.log('popover open');
    } else {
      openLink('app');
    }
  };

  const openLink = (destination: 'app' | 'web') => {
    if (!phone) {
      setError(t('errors.noPhone'));
    } else if (destination === 'app') {
      try {
        save(phone, Date.now());
        const link = document.createElement('a');
        link.href = `whatsapp://send?phone=${phone}`;
        link.click();
      } catch (e) {
        setError(t('errors.open'));
      }
    } else if (destination === 'web') {
      save(phone, Date.now());
      const link = document.createElement('a');
      link.href = `https://web.whatsapp.com/send?phone=${phone}`;
      link.target = '_blank';
      link.rel = 'noopener noreferrer';
      link.click();
    }
  };

  return (
    <>
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
        }}
      >
        <PhoneInput
          containerClass='phoneContainer'
          enableLongNumbers={true}
          autoFormat={false}
          country={countryCode}
          value={phone}
          placeholder={t('open.phonePlaceholder')}
          onChange={handlePhoneChange}
        />
      </div>

      <IonPopover
        isOpen={popoverState}
        onDidDismiss={() => setShowPopover(false)}
      >
        <IonList>
          <IonListHeader>{t('open.where')}</IonListHeader>
          <IonItem onClick={() => openLink('web')} button>
            {t('open.web')}
          </IonItem>
          <IonItem button onClick={() => openLink('app')}>
            {t('open.desktop')}
          </IonItem>
          <IonItem
            lines='none'
            detail={false}
            button
            onClick={() => setShowPopover(false)}
          >
            {t('open.close')}
          </IonItem>
        </IonList>
      </IonPopover>
      <IonButton expand='block' color='success' onClick={handleClick}>
        {t('open.openChat')}
      </IonButton>
      <IonToast
        isOpen={error ? true : false}
        position='top'
        message={error}
        color='danger'
        duration={2000}
      />
    </>
  );
};
export default OpenPhone;
