function twoDig(dig: number) {
  return ('0' + dig).slice(-2)
}

export const formatTimestamp: (miliseconds: number) => string = (miliseconds) => {
  const date = new Date(miliseconds)
  const year = date.getFullYear()
  const month = date.getMonth() + 1
  const day = date.getDate()
  const hour = date.getHours()
  const minute = date.getMinutes()
  return `${year}/${twoDig(month)}/${twoDig(day)} at ${twoDig(hour)}:${twoDig(minute)}`
}