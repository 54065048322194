import React, { useMemo } from 'react';
import {
  IonList,
  IonItem,
  IonAvatar,
  IonChip,
  IonCard,
  IonCardContent,
  IonCardSubtitle,
  IonCardHeader,
  IonCardTitle,
  IonButton,
  IonIcon,
} from '@ionic/react';
import { AlgoliaHit, City } from './Search';
import { getIndusriesObject } from '../../utils/industries';
import { locationOutline, logoWhatsapp } from 'ionicons/icons';
import { useTranslation } from 'react-i18next';

interface ResultsProps {
  results: AlgoliaHit[];
  query: string;
  countryName: string;
  category: string;
  city: City | false;
  allCities: boolean;
  searchNext: (page: number) => void;
  nextPage: number;
  saveToHistory: (to: string, date: number) => void;
}

function getInitials(name: string) {
  if (!name) return 'WA';
  return name
    .split(' ')
    .map((str) => str[0])
    .join('')
    .substring(0, 2)
    .toUpperCase();
}

const SearchResults: React.FC<ResultsProps> = ({
  results,
  query,
  countryName,
  city,
  category,
  allCities,
  searchNext,
  nextPage,
  saveToHistory,
}) => {
  const { t, i18n } = useTranslation('outside');

  const lang = useMemo(() => {
    return i18n.languages[0] === 'es' ? 'es' : 'en';
  }, [i18n.languages]);

  const categoriesObj: { [key: string]: string } = useMemo(() => {
    return getIndusriesObject(lang);
  }, [lang]);

  const openLink = (link: string) => {
    const walink = `wa.link/${link}`;
    const a = document.createElement('a');
    a.href = `https://${walink}`;
    a.click();
    saveToHistory(walink, Date.now());
  };

  return (
    <div className='search-results'>
      <IonList>
        <IonItem>
          <p>
            {t('search.resultsFor')} '{query.trim()}' {t('search.in')}{' '}
            {countryName} -{' '}
            {city && !allCities ? `${city.name}` : `${t('search.allCities')}`} -{' '}
            {category
              ? `${t('search.in')} ${category}`
              : `${t('search.allCategories')}`}
          </p>
        </IonItem>
        {results.map((r) => (
          <ResultCard
            key={r.objectID}
            result={r}
            openLink={openLink}
            buttonText={t('search.cardButtonText')}
            categories={r.categories.map((c) => categoriesObj[c])}
          />
        ))}
      </IonList>
      {nextPage > 0 && (
        <IonButton
          fill='clear'
          size='small'
          expand='full'
          onClick={() => searchNext(nextPage)}
        >
          {t('search.moreResults')}
        </IonButton>
      )}
    </div>
  );
};

interface CardProps {
  result: AlgoliaHit;
  openLink: (link: string) => void;
  categories: string[];
  buttonText: string;
}

const ResultCard: React.FC<CardProps> = ({
  result,
  openLink,
  categories,
  buttonText,
}) => {
  return (
    <IonCard onClick={() => openLink(result.link)}>
      <IonCardHeader>
        <IonItem>
          <IonAvatar slot='start' className='cardAvatar'>
            {result.image ? (
              <img
                src={`https://cdn.wa.link/${result.image}`}
                alt='Link logo'
              />
            ) : (
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'center',
                  alignContent: 'center',
                }}
              >
                <p style={{ marginTop: '11px', marginBottom: '0px' }}>
                  {getInitials(result.title)}
                </p>
              </div>
            )}
          </IonAvatar>
          <div>
            <IonCardSubtitle>
              <a
                style={{ fontWeight: 'bold' }}
                href={`https://wa.link/${result.link}`}
              >
                wa.link/{result.link}
              </a>
            </IonCardSubtitle>
            <div style={{ marginTop: '0.4rem', marginBottom: '0.4rem' }}>
              <IonCardTitle>{result.title}</IonCardTitle>
            </div>
          </div>
        </IonItem>
      </IonCardHeader>

      <IonCardContent>
        {result.description}
        <div
          style={{
            marginBottom: '0.4rem',
            marginTop: '0.5rem',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <span style={{ marginRight: '2px' }}>
            <IonIcon icon={locationOutline} />
          </span>
          <small>
            {result.cityName} - {result.country}
          </small>
        </div>
        <div>
          {categories.map((c) => (
            <IonChip key={c} color='medium' outline>
              {c}
            </IonChip>
          ))}
        </div>

        <div style={{ marginTop: '1rem' }}>
          <IonButton expand='block' color='success'>
            <span style={{ marginRight: '4px', marginTop: '2px' }}>
              <IonIcon icon={logoWhatsapp} />
            </span>
            {buttonText}
          </IonButton>
        </div>
      </IonCardContent>
    </IonCard>
  );
};

export default SearchResults;
