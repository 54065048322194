import React from 'react';
import {
  IonList,
  IonItem,
  IonCard,
  IonCardContent,
  IonCardHeader,
  IonCardTitle,
  IonButton,
} from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { City } from './Search';

interface ResultsProps {
  query: string;
  category: string;
  countryName: string;
  city: City | false;
  allCities: boolean;
}

const SearchNoResults: React.FC<ResultsProps> = ({
  query,
  category,
  countryName,
  city,
  allCities,
}) => {
  const { t } = useTranslation('outside');
  return (
    <IonList>
      <IonItem>
        <p>
          {t('search.noResults')} '{query.trim()}' {t('search.in')}{' '}
          {countryName} -{' '}
          {city && !allCities ? `${city.name}` : `${t('search.allCities')}`} -{' '}
          {category
            ? `${t('search.in')} ${category}`
            : `${t('search.allCategories')}`}
        </p>
      </IonItem>
      <IonCard>
        <IonCardHeader>
          <IonCardTitle>{t('search.beFirst')}</IonCardTitle>
        </IonCardHeader>

        <IonCardContent>
          {t('search.beFirstResult')} <strong>'{query.trim()}'?</strong>{' '}
          {t('search.buyPremium')}
          <div style={{ marginTop: '1rem' }}>
            <IonButton
              expand='block'
              color='success'
              href='https://create.wa.link/premium'
            >
              {t('search.getPremium')}
              {'  '}
              <span style={{ marginLeft: '2px' }} role='img' aria-label='crown'>
                {' '}
                👑{' '}
              </span>
            </IonButton>
          </div>
        </IonCardContent>
      </IonCard>
    </IonList>
  );
};

export default SearchNoResults;
