import React, { useEffect, useState } from 'react';
import { Redirect, Route } from 'react-router-dom';
import {
  IonApp,
  IonIcon,
  IonLabel,
  IonRouterOutlet,
  IonTabBar,
  IonTabButton,
  IonTabs,
} from '@ionic/react';
import { IonReactRouter } from '@ionic/react-router';
import {
  addOutline,
  chatboxEllipsesOutline,
  albumsOutline,
  searchOutline,
} from 'ionicons/icons';
import Header from './components/header/Header';
import Open from './pages/open/Open';
import History from './pages/history/History';
import { useTranslation } from 'react-i18next';
import axios from 'axios';

/* Core CSS required for Ionic components to work properly */
import '@ionic/react/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/react/css/normalize.css';
import '@ionic/react/css/structure.css';
import '@ionic/react/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/react/css/padding.css';
import '@ionic/react/css/float-elements.css';
import '@ionic/react/css/text-alignment.css';
import '@ionic/react/css/text-transformation.css';
import '@ionic/react/css/flex-utils.css';
import '@ionic/react/css/display.css';

/* Theme variables */
import './theme/variables.css';
import Search from './pages/search/Search';

export type HistoryList = { to: string; date: number }[] | undefined | 'false';

const App: React.FC = () => {
  const [history, setHistory] = useState<HistoryList>();
  const [countryCode, setCountryCode] = useState<string>('us');
  const { t } = useTranslation('outside');

  useEffect(() => {
    //set history for app
    const history = localStorage.getItem('history');
    if (history === 'false') {
      setHistory('false');
    } else {
      if (history) {
        const currentHistory = JSON.parse(history);
        setHistory(currentHistory);
      }
    }

    //set country for app
    const getCountry = async () => {
      const currentCountry = localStorage.getItem('country');
      if (!currentCountry) {
        try {
          const response = await axios.get('https://ip2c.org/s');
          const countryCode = response.data.split(';')[1];
          console.log('get country called');
          if (countryCode) {
            const country = countryCode.toLowerCase();
            changeCountry(country);
          } else {
            setCountryCode('us');
          }
        } catch (e) {
          setCountryCode('us');
        }
      } else {
        setCountryCode(currentCountry);
      }
    };
    getCountry();
  }, []);

  const changeCountry = (country: string) => {
    setCountryCode(country);
    localStorage.setItem('country', country);
  };

  const saveToHistory: (to: string, date: number) => void = (to, date) => {
    const history = localStorage.getItem('history');
    if (history !== 'false') {
      const newHistory = history
        ? [{ to, date }, ...JSON.parse(history)]
        : [{ to, date }];
      newHistory.slice(0, 100);
      localStorage.setItem('history', JSON.stringify([...newHistory]));
      setHistory(newHistory);
    }
  };

  return (
    <IonApp>
      <Header setHistory={(l: HistoryList) => setHistory(l)} />
      <IonReactRouter>
        <IonTabs>
          <IonRouterOutlet id='main'>
            <Route path='/open' exact={true}>
              <Open
                saveToHistory={saveToHistory}
                countryCode={countryCode}
                setCountryCode={(c: string) => changeCountry(c)}
              />
            </Route>
            <Route path='/search' exact={true}>
              <Search
                countryCode={countryCode}
                setCountryCode={(c: string) => changeCountry(c)}
                saveToHistory={saveToHistory}
              />
            </Route>
            <Route path='/history' exact={true}>
              <History
                history={history}
                setHistory={(l: HistoryList) => setHistory(l)}
              />
            </Route>
            <Route
              path='/'
              render={() => <Redirect to='/open' />}
              exact={true}
            />
            <Route render={() => (window.location.pathname = '/open')} />
          </IonRouterOutlet>
          <IonTabBar slot='bottom'>
            <IonTabButton tab='open' href='/open'>
              <IonIcon icon={chatboxEllipsesOutline} />
              <IonLabel>{t('app.open')}</IonLabel>
            </IonTabButton>
            <IonTabButton tab='search' href='/search'>
              <IonIcon icon={searchOutline} />
              <IonLabel>{t('app.search')}</IonLabel>
            </IonTabButton>
            <IonTabButton tab='history' href='/history'>
              <IonIcon icon={albumsOutline} />
              <IonLabel>{t('app.history')}</IonLabel>
            </IonTabButton>
            <IonTabButton href={t('app.createLink')}>
              <IonIcon icon={addOutline} />
              <IonLabel>{t('app.create')}</IonLabel>
            </IonTabButton>
          </IonTabBar>
        </IonTabs>
      </IonReactRouter>
    </IonApp>
  );
};

export default App;
