import React, { useState, useEffect } from 'react';
import {
  IonContent,
  IonPage,
  IonSegment,
  IonSegmentButton,
  IonLabel,
} from '@ionic/react';
import OpenPhone from './OpenPhone';
import OpenWalink from './OpenWalink';
import { useTranslation } from 'react-i18next';
import './Open.css';

interface OpenProps {
  saveToHistory: (to: string, date: number) => void;
  countryCode: string;
  setCountryCode: (c: string) => void;
}

const Open: React.FC<OpenProps> = ({
  saveToHistory,
  countryCode,
  setCountryCode,
}) => {
  const [tab, setTab] = useState<string>('phone');
  const [displayMode, setDisplayMode] = useState('browser');
  const { t } = useTranslation('outside');

  useEffect(() => {
    if (
      //@ts-ignore
      window.navigator.standalone ||
      window.matchMedia('(display-mode: standalone)').matches
    ) {
      setDisplayMode('standalone');
    }
    // Log launch display mode to analytics
  }, []);

  return (
    <IonPage>
      <IonContent>
        <div className='container'>
          <div className='open-content'>
            <div style={{ margin: '1rem' }}>
              <h1>
                <strong>{t('open.title')}</strong>
              </h1>
              <p>{t('open.description')}</p>
            </div>
            <IonSegment
              value={tab}
              onIonChange={(e) => setTab(e.detail.value!)}
            >
              <IonSegmentButton value='phone'>
                <IonLabel>{t('open.phone')}</IonLabel>
              </IonSegmentButton>
              <IonSegmentButton value='walink'>
                <IonLabel>wa.link</IonLabel>
              </IonSegmentButton>
            </IonSegment>
            {tab === 'phone' ? (
              <OpenPhone
                save={saveToHistory}
                countryCode={countryCode}
                setCountryCode={setCountryCode}
              />
            ) : (
              <OpenWalink save={saveToHistory} />
            )}
            {displayMode === 'browser' && (
              <div style={{ marginTop: '1rem' }}>
                <small>
                  {t('open.installHint')}{' '}
                  <a href={t('open.learnHowLink')}>{t('open.learnHow')}</a>
                </small>
              </div>
            )}
          </div>
        </div>
      </IonContent>
    </IonPage>
  );
};

export default Open;
