import React, { useState } from 'react';
import {
  IonItem,
  IonLoading,
  IonInput,
  IonButton,
  IonToast,
  IonPopover,
  IonList,
} from '@ionic/react';
import axios from 'axios';
import { useTranslation } from 'react-i18next';

interface OpenProps {
  save: (to: string, date: number) => void;
}
const OpenWalink: React.FC<OpenProps> = ({ save }) => {
  const [link, setLink] = useState<string>('');
  const [loading, setLoading] = useState(false);
  const [linkExists, setLinkExists] = useState(true);
  const [linkError, setLinkError] = useState<string>('');
  const { t } = useTranslation('outside');

  const handleLinkChange: (l: string) => void = (l) => {
    const cleanPath = l.replace(/[^0-9a-zA-Z-_]+/gi, '');
    setLink(cleanPath);
    if (linkError) {
      setLinkError('');
    }
  };

  const handleOpenLink = async () => {
    if (!link) {
      setLinkError(t('errors.pathError'));
    } else if (link.length < 3) {
      setLinkError(t('errors.pathTooShort'));
    } else {
      setLoading(true);
      try {
        const response = await axios.get(`https://search.wa.link/${link}`);
        if (response.data.available) {
          setLinkExists(false);
        } else {
          setLinkExists(true);
          const walink = `wa.link/${link}`;
          const a = document.createElement('a');
          a.href = `https://${walink}`;
          a.click();
          save(walink, Date.now());
        }
        setLoading(false);
      } catch (e) {
        console.log(e);
        setLinkError(t('errors.openLink'));
        setLoading(false);
      }
    }
  };

  return (
    <>
      <div className='open-walink'>
        <div style={{ margin: '1rem auto' }}>
          <IonItem>
            <IonInput
              value={link}
              color={linkError ? 'danger' : 'dark'}
              placeholder={t('open.walinkPath')}
              onIonChange={(e) => handleLinkChange(e.detail.value!)}
            />
          </IonItem>
        </div>
        <p>
          {t('open.goTo')}{' '}
          <span role='img' aria-label='right hand'>
            👉{' '}
          </span>
          <strong>wa.link/{link}</strong>
        </p>

        <IonPopover
          isOpen={!linkExists}
          onDidDismiss={() => setLinkExists(true)}
        >
          <div
            style={{
              margin: '0 auto',
              paddingLeft: '1rem',
              paddingRight: '1rem',
            }}
          >
            <p style={{ marginBottom: 0 }}>
              <strong>wa.link/{link}</strong> {t('open.notExist')}
            </p>
          </div>
          <IonList>
            <IonItem href={t('open.createLinkPremium')} button>
              {t('open.buyLink')}
            </IonItem>
            <IonItem
              lines='none'
              detail={false}
              button
              onClick={() => setLinkExists(true)}
            >
              {t('open.noThanks')}
            </IonItem>
          </IonList>
        </IonPopover>
        <div style={{ marginTop: '1rem' }}>
          <IonButton expand='block' color='success' onClick={handleOpenLink}>
            {t('open.openLink')}
          </IonButton>
        </div>
      </div>

      <IonLoading isOpen={loading} message={'Please wait...'} />
      <IonToast
        isOpen={linkError ? true : false}
        position='top'
        message={linkError}
        color='danger'
        duration={2000}
      />
    </>
  );
};

export default OpenWalink;
