import React, { useEffect, useState } from 'react';
import { IonButton, IonIcon } from '@ionic/react';
import { arrowDownCircleOutline } from 'ionicons/icons';

const InstallPWA = () => {
  const [supportsPWA, setSupportsPWA] = useState(false);
  const [promptInstall, setPromptInstall] = useState(null);

  useEffect(() => {
    const handler = (e) => {
      e.preventDefault();
      setSupportsPWA(true);
      setPromptInstall(e);
    };
    window.addEventListener('beforeinstallprompt', handler);

    return () => window.removeEventListener('transitionend', handler);
  }, []);

  const onClick = (evt) => {
    evt.preventDefault();
    if (!promptInstall) {
      return;
    }

    window.gtag('event', 'click_install');
    promptInstall.prompt();

    promptInstall.userChoice.then((choiceResult) => {
      if (choiceResult.outcome === 'accepted') {
        window.gtag('event', 'accepted_install');
      } else {
        window.gtag('event', 'rejected_install');
      }
    });
  };

  if (!supportsPWA) {
    return null;
  }
  return (
    <IonButton
      fill='outline'
      size='small'
      aria-label='Install app'
      title='Install app'
      onClick={onClick}
    >
      Install
      <IonIcon icon={arrowDownCircleOutline} />
    </IonButton>
  );
};

export default InstallPWA;
