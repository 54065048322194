import React, { useState, useEffect } from 'react';
import {
  IonItem,
  // IonHeader,
  IonToolbar,
  IonTitle,
  IonIcon,
  IonButton,
} from '@ionic/react';
import { ellipsisVerticalOutline } from 'ionicons/icons';
import logo from '../../img/logo-light.png';
import logoDark from '../../img/logo-dark.png';
import SettingsModal from '../SettingsModal';
import InstallPWA from '../InstallPWA';
import { HistoryList } from '../../App';
import './Header.css';

interface HeaderProps {
  setHistory: (l: HistoryList) => void;
}
const Header: React.FC<HeaderProps> = ({ setHistory }) => {
  const [showModal, setShowModal] = useState(false);
  const [isDark, setIsDark] = useState(false);
  const [saveChats, setSaveChats] = useState(false);

  useEffect(() => {
    const dark = localStorage.getItem('isDark');
    const history = localStorage.getItem('history');
    if (dark && dark === 'true') {
      document.body.classList.toggle('dark');
      setIsDark(true);
    } else {
      setIsDark(false);
    }
    if (history === 'false') {
      setSaveChats(false);
    } else {
      setSaveChats(true);
    }
  }, []);

  const toggleDarkMode: (check: boolean) => void = (check) => {
    if (check) {
      localStorage.setItem('isDark', 'true');
      document.body.classList.toggle('dark');
      setIsDark(true);
    } else {
      localStorage.removeItem('isDark');
      document.body.classList.toggle('dark');
      setIsDark(false);
    }
  };

  const toggleSaveChats: (check: boolean) => void = (check) => {
    if (check) {
      localStorage.removeItem('history');
      setHistory(undefined);
      setSaveChats(true);
    } else {
      localStorage.setItem('history', 'false');
      setSaveChats(false);
      setHistory('false');
    }
  };

  return (
    <div>
      {/* <IonHeader collapse='condense'> */}
      <IonToolbar mode='md'>
        <IonItem lines='none'>
          <img
            src={isDark ? logoDark : logo}
            height='auto'
            width='70px'
            alt='logo'
          />
          <IonTitle style={{ paddingLeft: '0.5rem' }}>
            <span
              style={{
                margin: '0',
                borderLeft: '1px solid #333',
                paddingLeft: '0.5rem',
              }}
            >
              <strong>Open</strong>
            </span>
          </IonTitle>
          <InstallPWA />
          <IonButton fill='clear' onClick={() => setShowModal(!showModal)}>
            <IonIcon
              slot='icon-only'
              icon={ellipsisVerticalOutline}
              onClick={() => setShowModal(!showModal)}
            />
          </IonButton>
        </IonItem>
      </IonToolbar>
      {/* </IonHeader> */}

      <SettingsModal
        isOpen={showModal}
        closeModal={() => setShowModal(false)}
        toggleDarkMode={toggleDarkMode}
        toggleSaveChats={toggleSaveChats}
        isDark={isDark}
        saveChats={saveChats}
      />
    </div>
  );
};

export default Header;
