import React, { useEffect, useState, useCallback } from 'react';
import { IonInput } from '@ionic/react';
import { useTranslation } from 'react-i18next';
import { City } from './Search';

let autoComplete: any;

const loadScript = (url, callback) => {
  const existingScript = document.getElementById('googleMaps');
  if (!existingScript) {
    let script = document.createElement('script');
    script.type = 'text/javascript';

    if ((script as any).readyState) {
      (script as any).onreadystatechange = function () {
        if (
          (script as any).readyState === 'loaded' ||
          (script as any).readyState === 'complete'
        ) {
          (script as any).onreadystatechange = null;
          callback();
        }
      };
    } else {
      script.onload = () => callback();
    }

    script.src = url;
    script.id = 'googleMaps';
    document.body.appendChild(script);
  } else {
    callback();
  }
};

const handleScriptLoad = (el, country, handler) => {
  autoComplete = new (window as any).google.maps.places.Autocomplete(el, {
    types: ['(cities)'],
  });
  autoComplete.setComponentRestrictions({ country: [country] });
  autoComplete.setFields(['place_id', 'name', 'formatted_address']);
  autoComplete.addListener('place_changed', () => handler());
};

interface SearchLocationProps {
  country: string;
  city: City | false;
  changeCity: (c: City) => void;
}

const SearchLocationInput: React.FC<SearchLocationProps> = ({
  country,
  city,
  changeCity,
}) => {
  const [cityName, setCityName] = useState('');
  const { t } = useTranslation('outside');

  const ref = useCallback(
    async (inputRef) => {
      if (inputRef) {
        const input = await inputRef.getInputElement();
        loadScript(
          `https://maps.googleapis.com/maps/api/js?key=AIzaSyBD87R6mtfHb4j5PGrhwRaiju571skDTOk&libraries=places`,
          () => handleScriptLoad(input, country, handlePlaceSelect)
        );
      }
    },
    //eslint-disable-next-line
    [country]
  );

  useEffect(() => {
    if (city && city.formatted_address) {
      setCityName(city.formatted_address);
    } else {
      setCityName('');
    }
  }, [city]);

  const handlePlaceSelect = () => {
    const place = autoComplete.getPlace();
    console.log(place);
    if (!place.place_id) {
      console.log('no place');
      setCityName('');
    } else {
      changeCity(place);
    }
  };

  return (
    <IonInput
      ref={ref}
      placeholder={t('search.enterCity')}
      type='search'
      value={cityName}
      onIonChange={(e) => setCityName(e.detail.value!)}
    />
  );
};

export default SearchLocationInput;
