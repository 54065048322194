import React from 'react';
import {
  IonSelect,
  IonSelectOption,
  IonToggle,
  IonItem,
  IonLabel,
} from '@ionic/react';

import ReactFlagsSelect from 'react-flags-select';
import { getIndustries } from '../../utils/industries';
import SearchLocationInput from './SearchLocationInput';
import { useTranslation } from 'react-i18next';
import { City } from './Search';

interface SearchFiltersProps {
  setCountryCode: (c: string) => void;
  setCategory: (c: string) => void;
  countryCode: string;
  category: string;
  countryName: string;
  city: City | false;
  changeCity: (c: City) => void;
  allCities: boolean;
  changeAllCities: (c: boolean) => void;
}

const SearchFilters: React.FC<SearchFiltersProps> = ({
  countryCode,
  setCountryCode,
  setCategory,
  category,
  countryName,
  city,
  changeCity,
  allCities,
  changeAllCities,
}) => {
  const { t, i18n } = useTranslation('outside');
  return (
    <div className='filtersContainer'>
      <p>{t('search.selectCountry')}:</p>
      <ReactFlagsSelect
        selected={countryCode.toUpperCase()}
        onSelect={(code) => setCountryCode(code.toLowerCase())}
        searchable={true}
      />
      <p>{t('search.selectCity')}:</p>

      <IonItem>
        <IonLabel>
          {t('search.AllCities')} {countryName}{' '}
        </IonLabel>
        <IonToggle
          checked={allCities}
          slot='end'
          onIonChange={(e) => changeAllCities(e.detail.checked)}
        />
      </IonItem>
      {!allCities && (
        <IonItem>
          <SearchLocationInput
            country={countryCode}
            city={city}
            changeCity={changeCity}
          />
        </IonItem>
      )}
      <p>{t('search.selectCategory')}:</p>
      <IonItem>
        <IonSelect
          onIonChange={(e) => setCategory(e.detail.value)}
          value={category}
          okText={t('search.okay')}
          cancelText={t('search.dismiss')}
        >
          <IonSelectOption value=''>
            {t('search.AllCategories')}
          </IonSelectOption>
          {getIndustries(i18n.languages[0]).map((i) => (
            <IonSelectOption key={i.id} value={i.id}>
              {i.label}
            </IonSelectOption>
          ))}
        </IonSelect>
      </IonItem>
    </div>
  );
};

export default SearchFilters;
